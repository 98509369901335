import React, {useEffect, useMemo, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Box, Switch, Tooltip, Typography} from "@mui/material";
import {FieldArray, FieldArrayRenderProps, Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../components/Buttons/Button";
import {Add, Clear} from "@mui/icons-material";
import SubmitErrorListener from "../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {WorkSheetWorkItem} from "../../../../API/workSheets/types";
import ModifierStartAndEndTime from "../../../../components/Form/ModifierStartAndEndTime";
import NumberTextField from "../../../../components/Form/NumberTextField";
import Autocomplete from "../../../../components/Form/Autocomplete";
import {SelectOptionWithId} from "../../../../types";
import dayjs, {Dayjs} from "dayjs";
import {getTripDefinitionLabel} from "../utils";
import {WorkScheduleItem} from "../../../../API/workSchedule/types";
import {selectResourceNameByType} from "../store/selectors";
import {workItemChangesValidationSchema} from "../../../../utils/formValidation";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector, useAppStore} from "../../../../hooks";
import {selectRegionById} from "../../../../store/regionSlice";
import {getTripWorkItemsOnDate} from "../../../../API";
import {setToast} from "../../../../store/toastSlice";
import {ApiError, RoutePoint, TripDefinition} from "../../../../API/types";
import ArrayAnchorMenu from "../../../../components/Form/ArrayAnchorMenu";
import RoutePointSelect from "../../../../components/Planner/components/Dialogs/SplitTrip/components/RoutePointSelect";
import StaticIcon from "../../../../components/Icons/StaticIcon";
import Loader from "../../../../components/Loader";
import {getDayjsTimeFromDateTimeStr} from "../../../../utils/dateUtils";
import {decimalToFixedDisplayStr, strToDecimal} from "../../../../utils/utils";


// TODO - remove feature flag when split work items fully supports using split by time and split by route together
const IS_SPLIT_BOTH_WAYS_FUNCTIONALITY_TOGGLED = false;

const createEmptyPoint = (
    newPointIndex: number | null = null,
    arrayHelpers: FieldArrayRenderProps | null = null,
): WorkItemChanges => {
    const emptyPoint = {
        workItemId: null,
        tripSegmentId: null,
        splitRoutePoint: null,
        startTime: null,
        startTimeIsOnNextDay: false,
        endTime: null,
        endTimeIsOnNextDay: false,
        distance: '',
        busWorkSheetId: null,
        driverWorkSheetId: null,
    }

    if (arrayHelpers && newPointIndex !== null) {
        if (newPointIndex === 0 && arrayHelpers.form.values.parts.length > 0) { // added to the start of array
            const nextPoint = arrayHelpers.form.values.parts[0];
            return {
                ...emptyPoint,
                startTime: nextPoint.startTime,
                startTimeIsOnNextDay: nextPoint.startTimeIsOnNextDay,
                endTime: nextPoint.startTime,
                endTimeIsOnNextDay: nextPoint.startTimeIsOnNextDay,
                splitRoutePoint: nextPoint.splitRoutePoint,
                distance: nextPoint.distance,
                busWorkSheetId: nextPoint.busWorkSheetId,
                driverWorkSheetId: nextPoint.driverWorkSheetId,
            };
        } else if (newPointIndex > 0 && newPointIndex < arrayHelpers.form.values.parts.length) { // added in the middle
            const previousPoint = arrayHelpers.form.values.parts[newPointIndex - 1];
            const nextPoint = arrayHelpers.form.values.parts[newPointIndex];
            return {
                ...emptyPoint,
                startTime: previousPoint.endTime,
                startTimeIsOnNextDay: previousPoint.endTimeIsOnNextDay,
                endTime: nextPoint.startTime,
                endTimeIsOnNextDay: nextPoint.startTimeIsOnNextDay,
                busWorkSheetId: nextPoint.busWorkSheetId,
                driverWorkSheetId: nextPoint.driverWorkSheetId,
            };
        } else if (newPointIndex === arrayHelpers.form.values.parts.length) { // added to the end of array
            const previousPoint = arrayHelpers.form.values.parts[newPointIndex - 1];
            return {
                ...emptyPoint,
                startTime: previousPoint.endTime,
                startTimeIsOnNextDay: previousPoint.endTimeIsOnNextDay,
                endTime: previousPoint.endTime,
                endTimeIsOnNextDay: previousPoint.endTimeIsOnNextDay,
                splitRoutePoint: previousPoint.splitRoutePoint,
                distance: previousPoint.distance,
                busWorkSheetId: previousPoint.busWorkSheetId,
                driverWorkSheetId: previousPoint.driverWorkSheetId,
            };
        }
    }

    return emptyPoint;
};

interface WorkItemChanges {
    workItemId: number | null;
    tripSegmentId: number | null;
    splitRoutePoint: RoutePoint | null;
    startTime: Dayjs | null;
    startTimeIsOnNextDay: boolean;
    endTime: Dayjs | null;
    endTimeIsOnNextDay: boolean;
    distance: string;
    busWorkSheetId: SelectOptionWithId | null;
    driverWorkSheetId: SelectOptionWithId | null;
}

export interface SplitWorkItemFormData {
    parts: WorkItemChanges[];
    isAvlVersion: boolean;
}

const splitWorkItemValidationSchema = (isAvlVersion: boolean) => Yup.object().shape({
    parts: Yup.array().of(workItemChangesValidationSchema(isAvlVersion))
});

export interface SplitWorkItemDialogData {
    workItem: WorkSheetWorkItem;
    driverWorkSheets: WorkScheduleItem[];
    busWorkSheets: WorkScheduleItem[];
}

const getCombinedRoute = (workItems: WorkSheetWorkItem[]): {route: RoutePoint[]} => {
    if (!workItems || !workItems.length) return ({route: []});
    if (workItems.length === 1) return ({route: workItems[0].route ?? []});

    const orderedWorkItems = workItems.sort((a, b) => a.startDateTime.localeCompare(b.startDateTime));
    const combinedRoute: RoutePoint[] = [];
    orderedWorkItems.forEach(workItem => {
        if (workItem.route?.length) {
            workItem.route.forEach(routePoint => {
                if (!combinedRoute.find(combinedRoutePoint => combinedRoutePoint.id === routePoint.id)) {
                    combinedRoute.push(routePoint);
                }
            });
        }
    });

    return {
        route: combinedRoute
    };
};

const ChangesForm = ({workItemId, tripCode, index, maxIndex, busOptions, driverOptions, arrayHelpers, isAvlVersion, route, formikProps}: {
    workItemId: number | null;
    tripCode: string;
    index: number,
    maxIndex: number,
    busOptions: SelectOptionWithId[],
    driverOptions: SelectOptionWithId[],
    arrayHelpers: FieldArrayRenderProps;
    isAvlVersion: boolean;
    route: RoutePoint[];
    formikProps: FormikProps<SplitWorkItemFormData>;
}) => {
    const prefix = `parts.${index}`;
    const isOk = !!(formikProps.values.parts[index].driverWorkSheetId && formikProps.values.parts[index].busWorkSheetId);

    return (
        <Box sx={index > 0 ? {borderTop: '1px solid', color: 'divider', mt: 2, pt: 1.5} : {}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', pb: 1}}>
                <Typography sx={{display: 'flex'}}>{tripCode} osa {index + 1}{workItemId === null ? ' (uus)' : ''}</Typography>
                <ArrayAnchorMenu<WorkItemChanges>
                    index={index}
                    maxIndex={maxIndex}
                    arrayHelpers={arrayHelpers}
                    createEmptyPoint={createEmptyPoint}
                />
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, minWidth: '200px'}}>
                <Box sx={{width: {xs: '100%', md: '55%'}, pr: {xs: 0, md: 2}}}>
                    {isAvlVersion ?
                        <RoutePointSelect name={prefix+'.splitRoutePoint'} label="Reisi osa alguspunkt" options={route} sx={{my: 1}} />
                        :
                        <ModifierStartAndEndTime prefix={prefix} />
                    }
                    <NumberTextField name={prefix+'.distance'} label="Pikkus (km)" decimals={2} />
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', width: {xs: '100%', md: '45%'}}}>
                    <Autocomplete
                        name={prefix+'.busWorkSheetId'}
                        label="Buss"
                        options={busOptions}
                        getOptionLabel={option => option.name}
                    />
                    <Autocomplete
                        name={prefix+'.driverWorkSheetId'}
                        label="Juht"
                        options={driverOptions}
                        getOptionLabel={option => option.name}
                    />
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <StaticIcon type="INFO" color={isOk ? 'secondary.light' : 'error.light'} tooltipTitle={`
                            Reisi osa on tühistatud, kui sellel puudub buss ja/või juht. 
                            Reisi osa on käigus, kui sellele on määratud buss ja juht. 
                        `} />
                        <Typography>{isOk ? 'käigus' : 'tühistatud'}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

interface SplitWorkItemDialogProps {
    dialogData: SplitWorkItemDialogData;
    handleCloseDialog: () => void;
    handleSave: (tripId: number, formData: SplitWorkItemFormData, isAvlVersion: boolean) => void;
}

const SplitWorkItemDialog = ({dialogData, handleCloseDialog, handleSave}: SplitWorkItemDialogProps) => {
    const store = useAppStore();
    const state = store.getState();
    const dispatch = useAppDispatch();
    const tripRegion = useAppSelector(state => selectRegionById(state, dialogData.workItem.tripDefinitionRegionId ?? 0));
    const date = useMemo(() => dayjs(dialogData.workItem.date), [dialogData.workItem]);

    const [isAvlVersion, setIsAvlVersion] = useState(!!tripRegion?.reportToAvl);
    const [trip, setTrip] = useState<TripDefinition|undefined>(undefined);
    const [workItems, setWorkItems] = useState<WorkSheetWorkItem[]>([]);
    const [initialValues, setInitialValues] = useState<SplitWorkItemFormData | undefined>(undefined);
    const {route} = useMemo(() => getCombinedRoute(workItems), [workItems]);

    useEffect(() => {
        if (dialogData.workItem.tripId) {
            getTripWorkItemsOnDate(dialogData.workItem.tripId, dialogData.workItem.date)
                .then((result) => {
                    setTrip(result.trip);
                    setWorkItems(result.workItems);
                })
                .catch((error) => {
                    handleError(error)
                });

        } else {
            handleError();
        }
    }, []);

    useEffect(() => {
        if (workItems) {
            const tripSegmentIds: number[] = [];
            workItems.forEach(workItem => {
                if (workItem.tripSegmentId && !tripSegmentIds.includes(workItem.tripSegmentId)) tripSegmentIds.push(workItem.tripSegmentId);
            });

            const parts: WorkItemChanges[] = workItems.map((workItem, index) => ({
                workItemId: workItem.id,
                tripSegmentId: workItem.tripSegmentId ?? null,
                splitRoutePoint: workItem.route?.length && (index === 0 || tripSegmentIds.length > 1) ? workItem.route[0] : null,
                startTime: getDayjsTimeFromDateTimeStr(workItem.startDateTime),
                startTimeIsOnNextDay: dayjs(workItem.startDateTime).isAfter(date, 'day'),
                endTime: workItems.length > 1 && workItem.endDateTime ? getDayjsTimeFromDateTimeStr(workItem.endDateTime) : null,
                endTimeIsOnNextDay: workItems.length > 1 && workItem.endDateTime ? dayjs(workItem.endDateTime).isAfter(date, 'day') : false,
                distance: workItem.distance?.toString() ?? '',
                busWorkSheetId: getBusOption(workItem),
                driverWorkSheetId: getDriverOption(workItem),
            }));

            if (parts.length === 1) {
                parts.push({
                    ...createEmptyPoint(),
                    busWorkSheetId: parts[0].busWorkSheetId,
                    driverWorkSheetId: parts[0].driverWorkSheetId,
                    endTime: getDayjsTimeFromDateTimeStr(dialogData.workItem.endDateTime),
                    endTimeIsOnNextDay: dayjs(dialogData.workItem.endDateTime).isAfter(date, 'day')
                });
            }

            setInitialValues({
                parts: parts,
                isAvlVersion: isAvlVersion
            });
        }
    }, [workItems]);

    const handleError = (error?: ApiError) => {
        dispatch(setToast({type: 'error', text: error?.message ?? 'Reisi leidmisel tekkis viga'}));
        handleCloseDialog();
    }

    const getBusOption = (workItem: WorkSheetWorkItem): SelectOptionWithId | null => {
        const workSheet = dialogData.busWorkSheets.find(item => item.id === workItem.busWorkSheetId);

        if (!workSheet) {
            return null;
        }

        return {
            id: workSheet.id as number,
            name: selectResourceNameByType(state, workSheet.resourceId as number, workSheet.resourceType),
        }
    };

    const getDriverOption = (workItem: WorkSheetWorkItem): SelectOptionWithId | null => {
        const workSheet = dialogData.driverWorkSheets.find(item => item.id === workItem.driverWorkSheetId);

        if (!workSheet) {
            return null;
        }

        return {
            id: workSheet.id as number,
            name: selectResourceNameByType(state, workSheet.resourceId as number, workSheet.resourceType),
        }
    };

    const handleSubmit = (data: SplitWorkItemFormData, helpers: FormikHelpers<SplitWorkItemFormData>) => {
        if (dialogData.workItem.tripId) handleSave(dialogData.workItem.tripId, data, isAvlVersion);
        helpers.setSubmitting(false);
    };

    const busOptions: SelectOptionWithId[] = dialogData.busWorkSheets.map(item => ({
        id: item.id as number,
        name: selectResourceNameByType(state, item.resourceId ?? 0, item.resourceType),
    }));
    const driverOptions: SelectOptionWithId[] = dialogData.driverWorkSheets.map(item => ({
        id: item.id as number,
        name: selectResourceNameByType(state, item.resourceId ?? 0, item.resourceType),
    }));

    const renderTotalDistanceDiffersError = (expectedTotalDistance: number, totalDistance: number) => {
        const totalDiff = expectedTotalDistance - totalDistance;

        return totalDiff !== 0 ? (
            <Alert severity="info" variant="outlined" sx={{mb: 1}}>
                Jaotatud lõikude pikkuste summa on {decimalToFixedDisplayStr(Math.abs(totalDiff))} km {
                totalDiff > 0 ? 'väiksem' : 'suurem'} kui reisi oodatud pikkus ({decimalToFixedDisplayStr(expectedTotalDistance)} km).
            </Alert>
        ) : null;
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
            <Box sx={{minWidth: 'min(800px, 80vw)', maxWidth: '100%'}}>
                <DialogTitle>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end'}}>
                        <Typography variant="h5" component="div">Halda reisi osadena</Typography>
                        {IS_SPLIT_BOTH_WAYS_FUNCTIONALITY_TOGGLED &&
                            <Tooltip placement="top" arrow title={tripRegion?.reportToAvl ? 'Selle piirkonna reisi saab ainult peatustepõhiselt jaotada' : undefined}>
                                <Box sx={{display: 'flex', alignItems: 'center', minWidth: 'fit-content'}}>
                                    <Switch
                                        checked={isAvlVersion}
                                        disabled={!!tripRegion?.reportToAvl}
                                        onChange={() => setIsAvlVersion(prevState => !prevState)} sx={{ml: -0.5}}
                                    />
                                    <Typography color="text.secondary">{isAvlVersion ? 'Peatustepõhine' : 'Kellaajapõhine'}</Typography>
                                </Box>
                            </Tooltip>
                        }
                    </Box>
                    <Typography variant="body2" component="div">{getTripDefinitionLabel(dialogData.workItem)}</Typography>
                </DialogTitle>
                {initialValues &&
                    <Formik enableReinitialize
                            initialValues={initialValues}
                            validationSchema={splitWorkItemValidationSchema(isAvlVersion)}
                            onSubmit={handleSubmit}>
                        {(formikProps: FormikProps<SplitWorkItemFormData>) => {
                            useEffect(() => {
                                formikProps.setFieldValue('isAvlVersion', isAvlVersion);
                            }, [isAvlVersion]);

                            return (
                                <Form>
                                    <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                        {renderTotalDistanceDiffersError(
                                            trip?.distance ?? 0,
                                            formikProps.values.parts.reduce((sum, part) => sum + strToDecimal(part.distance), 0)
                                        )}
                                        {formikProps.values.parts.length > 0 ?
                                            <FieldArray
                                                name="parts"
                                                render={arrayHelpers =>
                                                    <Box>
                                                        {formikProps.values.parts.map((part, index) =>
                                                            <ChangesForm
                                                                workItemId={part.workItemId}
                                                                tripCode={dialogData.workItem.tripDefinitionCode ?? ''}
                                                                key={index}
                                                                index={index}
                                                                maxIndex={formikProps.values.parts.length - 1}
                                                                busOptions={busOptions}
                                                                driverOptions={driverOptions}
                                                                arrayHelpers={arrayHelpers}
                                                                isAvlVersion={isAvlVersion}
                                                                route={route}
                                                                formikProps={formikProps}
                                                            />
                                                        )}
                                                        <Button
                                                            size="small"
                                                            text="Lisa uus punkt"
                                                            onClick={() => arrayHelpers.push(createEmptyPoint(formikProps.values.parts.length, arrayHelpers))}
                                                            startIcon={<Add/>}
                                                            variant="text"
                                                        />
                                                    </Box>
                                                }
                                            />
                                            :
                                            <Box sx={{minHeight: '100px', minWidth: '100px'}}>
                                                <Loader isTimeoutIgnored={true} />
                                            </Box>
                                        }
                                    </DialogContent>
                                    <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                        <Box maxWidth="50%">
                                            <Button
                                                disabled={formikProps.isSubmitting}
                                                text="Loobu"
                                                color="secondary"
                                                startIcon={<Clear/>}
                                                onClick={handleCloseDialog}
                                            />
                                        </Box>
                                        <Box maxWidth="50%">
                                            <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit"/>
                                        </Box>
                                    </DialogActions>
                                    <SubmitErrorListener
                                        isValid={formikProps.isValid}
                                        isValidating={formikProps.isValidating}
                                        isSubmitting={formikProps.isSubmitting}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                }
            </Box>
        </Dialog>
    );
};

export default SplitWorkItemDialog;