import React, {useMemo, useState} from "react";
import Button from "../../../../components/Buttons/Button";
import Dialog from "@mui/material/Dialog";
import {Box, Typography} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Clear} from "@mui/icons-material";
import SubmitErrorListener from "../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {ResourceType, WorkGroupItemType, WorkSheetDetails, WorkSheetWorkItem} from "../../../../API/types";
import Select from "../../../../components/Form/Select";
import {getWorkGroupItemTypeTranslationFromStr} from "../../../../utils/enumTranslations";
import ModifierStartAndEndTime from "../../../../components/Form/ModifierStartAndEndTime";
import NumberTextField from "../../../../components/Form/NumberTextField";
import {workGroupActivityValidationSchema} from "../../../../utils/formValidation";
import dayjs from "dayjs";
import {useAppStore} from "../../../../hooks";
import {SelectOptionWithIdAndTripComment, WorkItemForm} from "../types";
import {selectResourceNameByType} from "../store/selectors";
import Autocomplete from "../../../../components/Form/Autocomplete";
import {WorkScheduleItem} from "../../../../API/workSchedule/types";
import {SelectOptionWithId} from "../../../../types";
import Route from "../../../../components/Form/Route";
import {getTripDefinitionLabel} from "../utils";
import TextField from "../../../../components/Form/TextField";
import {workGroupItemTypesWithComment, workGroupItemTypesWithDistance} from "../../../../API/workSheets/types";

const emptyValues: WorkItemForm = {
    type: WorkGroupItemType.TRIP_DEFINITION,
    tripDefinition: null,
    startTime: null,
    startTimeIsOnNextDay: false,
    endTime: null,
    endTimeIsOnNextDay: false,
    route: [],
    distance: '',
    comment: '',
    oppositeResource: null,
};

export interface WorkItemDialogData {
    workSheet: WorkSheetDetails;
    workItem?: WorkSheetWorkItem;
    unplannedWorkItems: WorkSheetWorkItem[];
    oppositeWorkSheets: WorkScheduleItem[];
}

interface WorkItemDialogProps {
    dialogData: WorkItemDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: WorkItemForm, workItem?: WorkSheetWorkItem) => void;
}

const typesRequireOppositeResource = [
    WorkGroupItemType.TRIP_DEFINITION,
    WorkGroupItemType.PREPARATION_TIME,
    WorkGroupItemType.FINISHING_TIME,
    WorkGroupItemType.RESERVE,
    WorkGroupItemType.DEADHEADING,
    WorkGroupItemType.MAINTENANCE,
];

export default function WorkItemDialog({dialogData, handleCloseDialog, handleSave}: WorkItemDialogProps) {
    const store = useAppStore();
    const state = store.getState();
    const [selectedWorkSheetItem, setSelectedWorkSheetItem] = useState<WorkSheetWorkItem | undefined>(undefined);

    const { workSheet, workItem, unplannedWorkItems, oppositeWorkSheets } = dialogData;

    const date = useMemo(() => dayjs(workSheet.startDate), [workSheet]);

    const getOppositeResource = (workItem: WorkSheetWorkItem, resourceType: ResourceType): SelectOptionWithId | null => {
        if (!typesRequireOppositeResource.includes(workItem.type)) {
            return null;
        }

        const oppositeWorkSheetId = resourceType === ResourceType.DRIVER ? workItem.busWorkSheetId : workItem.driverWorkSheetId;
        const oppositeWorkSheet = oppositeWorkSheets.find(item => item.id === oppositeWorkSheetId);

        if (!oppositeWorkSheet) {
            return null;
        }

        return {
            id: oppositeWorkSheet.id as number,
            name: selectResourceNameByType(state, oppositeWorkSheet.resourceId as number, oppositeWorkSheet.resourceType),
        }
    };

    const getTripDefinitionOption = (workItem?: WorkSheetWorkItem): SelectOptionWithId | null => {
        if (!workItem || !workItem.tripSegmentId) {
            return null;
        }

        return {
            id: workItem.tripSegmentId,
            name: getTripDefinitionLabel(workItem),
        }
    };

    const initialValues: WorkItemForm = workItem ? {
        type: workItem.type,
        tripDefinition: getTripDefinitionOption(workItem),
        startTime: dayjs(workItem.startDateTime),
        startTimeIsOnNextDay: dayjs(workItem.startDateTime).isAfter(date, 'day'),
        endTime: dayjs(workItem.endDateTime),
        endTimeIsOnNextDay: dayjs(workItem.endDateTime).isAfter(date, 'day'),
        route: workItem.route?.map(point => ({
            geoPoint: point,
            stopName: point.stopName ?? '',
            requestStop: point.requestStop,
            time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
            timeIsOnNextDay: point.timeIsOnNextDay ?? false,
        })) ?? [],
        distance: workItem.distance?.toString() ?? '',
        comment: workItem.comment ?? '',
        oppositeResource: getOppositeResource(workItem, workSheet.resourceType),
    } : emptyValues;

    const handleSubmit = (form: WorkItemForm, formHelpers: FormikHelpers<WorkItemForm>) => {
        formHelpers.setSubmitting(true);
        if (selectedWorkSheetItem) {
            handleSave(form, selectedWorkSheetItem);
        } else {
            handleSave(form, workItem);
        }
        handleCloseDialog();
    };

    const getFilteredTypeOptions = (): WorkGroupItemType[] => {
        const workGroupActivityTypes = Object.values(WorkGroupItemType);
        const worksheetIncludesLunchOrDisruption = workSheet.workItems.some(activity =>
            activity.type === WorkGroupItemType.LUNCH_BREAK || activity.type === WorkGroupItemType.DISRUPTION
        );
        return worksheetIncludesLunchOrDisruption && !workItem
            ? workGroupActivityTypes.filter(type => type !== WorkGroupItemType.LUNCH_BREAK && type !== WorkGroupItemType.DISRUPTION)
            : workGroupActivityTypes;
    };

    const tripOptions: SelectOptionWithIdAndTripComment[] = unplannedWorkItems.filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION)
        .map(item => {
            return {
                id: item.tripSegmentId as number,
                name: getTripDefinitionLabel(item),
                tripComment: item.tripDefinitionComment
            }
        });

    if (workItem) {
        tripOptions.push({
            id: workItem.tripSegmentId as number,
            name: getTripDefinitionLabel(workItem),
            tripComment: workItem.tripDefinitionComment
        });
    }

    const handleChangeTripDefinition = (formikProps: FormikProps<WorkItemForm>) => (value: SelectOptionWithId | null) => {
        const workSheetItem = unplannedWorkItems.find(item => item.tripSegmentId === value?.id);
        if (workSheetItem && workSheetItem.tripSegmentId) {
            void formikProps.setValues({
                ...formikProps.values,
                tripDefinition: getTripDefinitionOption(workSheetItem),
                route: workSheetItem.route?.map(point => ({
                    geoPoint: point,
                    stopName: point.stopName ?? '',
                    requestStop: point.requestStop,
                    time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
                    timeIsOnNextDay: point.timeIsOnNextDay ?? false,
                })) ?? [],
                startTime: dayjs(workSheetItem.startDateTime),
                startTimeIsOnNextDay: !dayjs(workSheetItem.startDateTime).isSame(date, 'day'),
                endTime: dayjs(workSheetItem.endDateTime),
                endTimeIsOnNextDay: !dayjs(workSheetItem.endDateTime).isSame(date, 'day'),
                distance: workSheetItem.distance?.toString() ?? '',
                comment: '',
                oppositeResource: getOppositeResource(workSheetItem, workSheet.resourceType),
            });
        }
        if (workSheetItem && workSheetItem.id) {
            setSelectedWorkSheetItem(workSheetItem);
        }
    };

    const handleChangeType = (formikProps: FormikProps<WorkItemForm>) => (value: WorkGroupItemType) => {
        void formikProps.setValues({
            ...formikProps.values,
            type: value,
            tripDefinition: value === WorkGroupItemType.TRIP_DEFINITION ? (formikProps.values.tripDefinition ?? null) : null,
            route: value === WorkGroupItemType.TRIP_DEFINITION ? formikProps.values.route : [],
            distance: workGroupItemTypesWithDistance.includes(value) ? formikProps.values.distance : '',
            comment: workGroupItemTypesWithComment.includes(value) ? formikProps.values.comment : '',
        });
    };

    const secondaryResourceOptions: SelectOptionWithId[] = oppositeWorkSheets.map(item => ({
        id: item.id as number,
        name: selectResourceNameByType(state, item.resourceId ?? 0, item.resourceType),
    }));

    const renderTripComment = (formikProps: FormikProps<WorkItemForm>) => {
        const tripComment = tripOptions.find(option => option.id === formikProps.values.tripDefinition?.id)?.tripComment;

        return tripComment
            ? <Typography variant="body2" color="text.secondary" pb={0.5}>Reisi kommentaar: {tripComment}</Typography>
            : <></>;
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">{workItem ? 'Tegevuse muutmine' : 'Uus tegevus'}</Typography>
                </DialogTitle>
                <Formik initialValues={initialValues}
                        validationSchema={workGroupActivityValidationSchema}
                        onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<WorkItemForm>) =>
                        <Form>
                            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                <Select name="type"
                                        label="Tegevuse tüüp"
                                        options={getFilteredTypeOptions()}
                                        translationFunction={getWorkGroupItemTypeTranslationFromStr}
                                        translationEnumType={WorkGroupItemType}
                                        onChange={handleChangeType(formikProps)}
                                        disabled={!!workItem}
                                />
                                {formikProps.values.type === WorkGroupItemType.TRIP_DEFINITION &&
                                    <>
                                        <Autocomplete
                                            name="tripDefinition"
                                            label="Reis"
                                            options={tripOptions}
                                            onChange={handleChangeTripDefinition(formikProps)}
                                            getOptionLabel={(option) => option.name}
                                            disabled={!!workItem}
                                        />
                                        {renderTripComment(formikProps)}
                                        <Route
                                            name="route"
                                            values={formikProps.values.route}
                                        />
                                    </>
                                }
                                <ModifierStartAndEndTime />
                                {workGroupItemTypesWithDistance.includes(formikProps.values.type) &&
                                    <NumberTextField name="distance" label="Pikkus (km)" decimals={3} />
                                }
                                {typesRequireOppositeResource.includes(formikProps.values.type) &&
                                    <Autocomplete
                                        name="oppositeResource"
                                        label={workSheet.resourceType === ResourceType.DRIVER ? 'Buss' :'Juht'}
                                        options={secondaryResourceOptions}
                                        getOptionLabel={option => option.name}
                                    />
                                }
                                {workGroupItemTypesWithComment.includes(formikProps.values.type) &&
                                    <TextField
                                        name="comment"
                                        label="Kommentaar"
                                        multiline
                                        minRows={2}
                                        maxRows={5}
                                        required={false}
                                    />
                                }
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        text="Loobu"
                                        color="secondary"
                                        startIcon={<Clear />}
                                        onClick={handleCloseDialog}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit" />
                                </Box>
                            </DialogActions>
                            <SubmitErrorListener
                                isValid={formikProps.isValid}
                                isValidating={formikProps.isValidating}
                                isSubmitting={formikProps.isSubmitting}
                            />
                        </Form>
                    }
                </Formik>
            </Box>
        </Dialog>
    );
}
