import {WorkSheet, WorkSheetWorkItem} from "../../../API/workSheets/types";
import {getOtherRegionId, getStartAndEndPointLabel} from "../../../utils/utils";
import {Region} from "../../../API/region/types";

export const getTripDefinitionLabel = (item: WorkSheetWorkItem): string => {
    const routeName = getStartAndEndPointLabel(item.route);
    const lineNumber = item.tripDefinitionLineNumber && `(liin ${item.tripDefinitionLineNumber})`;

    return `${item.tripDefinitionCode}${lineNumber ? ' ' + lineNumber : ''}${routeName ? ' ' + routeName : ''}`;
};

export interface CodedRegionalItem {
    workGroupCode?: string;
    otherRegionId: number | null;
}

export const compareRegionalItems = (a: CodedRegionalItem, b: CodedRegionalItem) => {
    return (a.otherRegionId ?? 0) - (b.otherRegionId ?? 0) || (a.workGroupCode ?? '').localeCompare(b.workGroupCode ?? '');
};

export const compareByRegionAndWorkGroupCode = (a: WorkSheet, b: WorkSheet, selectedRegion: Region | undefined) => {
    return compareRegionalItems(
        {
            workGroupCode: a.workGroupCode,
            otherRegionId: getOtherRegionId(a.regionId, selectedRegion),
        },
        {
            workGroupCode: b.workGroupCode,
            otherRegionId: getOtherRegionId(b.regionId, selectedRegion),
        }
    );
};