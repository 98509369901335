import React from 'react';
import {WorkGroupItemType} from "../../../../../../API/types";
import {Box, Typography} from "@mui/material";
import {PLANNER_ROW_HEIGHT} from "../../../../constants";
import {getActivityCardColor, markerColors} from "../../../../utils/colorUtils";
import {green} from '@mui/material/colors';
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {getDisplayActivityName} from "../../../../utils/displayItemUtils";
import {EntityId} from "@reduxjs/toolkit";
import {
    deleteWorkSheetActivity,
    removeActivityFromWorkGroup,
    selectOppositeGroupMarkerColorMap,
    selectPlannerType,
    selectSelectedOppositeGroupId,
    setDialogData,
    setSelectedOppositeGroupId,
    updateActivityWorkSheet
} from "../../../../../../store/plannerSlice";
import {
    DisplayWorkGroupActivity,
    DisplayWorkItem,
    PlannerType,
    StartAndEndTimeWithModifierAndId
} from '../../../../types';
import {useDrag} from "react-dnd";
import PlannerItemTooltip from "../../../Tooltip";


export const MIN_ACTIVITY_CARD_WIDTH = 20;

interface ActivityCardProps {
    activity: DisplayWorkGroupActivity | DisplayWorkItem;
    currentGroupId: EntityId;
    isRowDisabled?: boolean;
    groupOfOppositeTypeId?: number;
}

const ActivityCard = ({activity, currentGroupId, isRowDisabled = false, groupOfOppositeTypeId}: ActivityCardProps) => {
    const dispatch = useAppDispatch();

    const plannerType = useAppSelector(selectPlannerType);
    const oppositeGroupMarkerColorMap = useAppSelector(selectOppositeGroupMarkerColorMap);
    const selectedOppositeGroupId = useAppSelector(selectSelectedOppositeGroupId);

    const color = getActivityCardColor(activity.type);
    const displayWorkGroupOfOppositeTypeMarker = !isRowDisabled && (activity.type === WorkGroupItemType.PREPARATION_TIME
    || activity.type === WorkGroupItemType.FINISHING_TIME || activity.type === WorkGroupItemType.RESERVE
    || activity.type === WorkGroupItemType.DEADHEADING || activity.type === WorkGroupItemType.MAINTENANCE);

    const [{ isDragging }, drag] = useDrag<StartAndEndTimeWithModifierAndId | {id: number}, any, { isDragging: boolean }>({
        type: activity.type,
        item: activity,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (activity, monitor) => {
            const dropResult = monitor.getDropResult<{id: number}>();
            if (dropResult !== null && plannerType === PlannerType.WORK_SHEET) {
                dispatch(updateActivityWorkSheet({
                    movedActivityId: activity.id,
                    originWorkSheetId: currentGroupId,
                    targetWorkSheetId: dropResult.id
                }));
            }
        },
    });

    const handleEdit = () => {
        dispatch(setDialogData({
            editActivity: {
                groupId: currentGroupId,
                activityId: activity.id,
                activity: {
                    type: activity.type,
                    startTime: activity.startTime,
                    startTimeIsOnNextDay: activity.startTimeIsOnNextDay,
                    endTime: activity.endTime,
                    endTimeIsOnNextDay: activity.endTimeIsOnNextDay,
                    distance: activity.distance?.toString() ?? '',
                    comment: activity.comment,
                }
            }
        }))
    };

    const handleDelete = () => {
        if (plannerType === PlannerType.WORK_GROUP) {
            dispatch(removeActivityFromWorkGroup({activityId: activity.id, groupId: currentGroupId}));
        }
        if (plannerType === PlannerType.WORK_SHEET) {
            dispatch(deleteWorkSheetActivity({activityId: activity.id, groupId: currentGroupId}));
        }
    };

    const renderOppositeGroupMarker = () => {
        return activity.width > 10 && groupOfOppositeTypeId && oppositeGroupMarkerColorMap ?
            <Box
                onClick={() => {
                    dispatch(setSelectedOppositeGroupId(groupOfOppositeTypeId));
                }}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '8px',
                    width: '8px',
                    borderRadius: '20px',
                    backgroundColor: markerColors[oppositeGroupMarkerColorMap[groupOfOppositeTypeId]],
                    border: '2px solid white',
                    cursor: 'pointer'
                }}
            />
            :
            <></>;
    };

    const renderAddOppositeGroupMarker = () => {
        return (
            <Box
                onClick={() => {
                    if (plannerType === PlannerType.WORK_GROUP) {
                        dispatch(setDialogData({
                            addOppositeWorkGroupToActivity: {workGroupId: currentGroupId, activityId: activity.id}
                        }));
                    }
                    if (plannerType === PlannerType.WORK_SHEET) {
                        dispatch(setDialogData({
                            addOppositeWorkSheetToWorkItem: {workSheetId: currentGroupId, workItemId: activity.id}
                        }));
                    }
                }}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '10px',
                    width: '10px',
                    borderRadius: '20px',
                    backgroundColor: 'common.white',
                    border: '1px solid white',
                    cursor: isRowDisabled ? '' : 'pointer',
                }}
            >
                <Typography sx={{
                    fontSize: '14px',
                    lineHeight: '8px',
                    position: 'absolute',
                    top: '0px',
                    right: '1px',
                    color: green[500],
                    fontWeight: 600
                }}>
                    +
                </Typography>
            </Box>
        );
    };

    return (
        <Box key={activity.id} sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            boxSizing: 'border-box',
            mt: `-${PLANNER_ROW_HEIGHT - 2}px`,
        }}>
            <PlannerItemTooltip
                isDragging={isDragging}
                item={activity}
                handleActivityEdit={handleEdit}
                handleActivityDelete={handleDelete}
                isRowDisabled={isRowDisabled}
            >
                <Box ref={isRowDisabled || plannerType !== PlannerType.WORK_SHEET ? undefined : drag} sx={{
                    position: 'relative',
                    zIndex: 10,
                    height: `${PLANNER_ROW_HEIGHT - 6}px`,
                    borderRadius: '5px 15px 15px 5px',
                    my: '2px',
                    lineHeight: '15px',
                    left: activity.xPos,
                    maxWidth: activity.width,
                    minWidth: activity.width,
                    backgroundColor: color,
                    boxSizing: 'border-box',
                    cursor: isRowDisabled || plannerType !== PlannerType.WORK_SHEET ? '' : 'pointer',
                    opacity: isDragging || (!isRowDisabled && selectedOppositeGroupId && selectedOppositeGroupId !== groupOfOppositeTypeId) ? 0.5 : 1
                }}>
                    <Box sx={{position: 'relative', padding: activity.width > MIN_ACTIVITY_CARD_WIDTH ? '6px 4px' : '6px 1px'}}>
                        {displayWorkGroupOfOppositeTypeMarker &&
                            (groupOfOppositeTypeId ? renderOppositeGroupMarker() : renderAddOppositeGroupMarker())}
                        <Typography sx={{color: 'white', fontWeight: 'bolder', fontSize: '12px'}}>
                            {getDisplayActivityName(activity)}
                        </Typography>
                    </Box>
                </Box>
            </PlannerItemTooltip>
        </Box>
    )
};

export default ActivityCard;
