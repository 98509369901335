import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {
    createWorkItem,
    deleteOdometerReading,
    deleteWorkItem,
    getUnplannedWorkItems,
    getWorkSheet,
    getWorkSheetsByRegionAndDate,
    saveOdometerReading,
    saveTripWorkItemsOnDate,
    saveTripWorkItemsWithRouteOnDate,
    updateOdometerReading as apiUpdateOdometerReading,
    updateWorkItem,
    updateWorkScheduleItem,
} from "../../../../API";
import {
    ApiError,
    OdometerReadingRequest,
    OdometerReadingWithBusId,
    ResourceType,
    RoutePointRequest,
    SaveWorkItem,
    SaveWorkScheduleItem,
    WorkGroupItemType,
    WorkScheduleItemStatus,
    WorkScheduleItemType,
    WorkSheetDetails as ApiWorkSheetDetails,
    WorkSheetWorkItem
} from "../../../../API/types";
import LoaderWithChildren from "../../../../components/LoaderWithChildren";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {fetchBuses} from "../../../../store/busSlice";
import {fetchDrivers} from "../../../../store/driverSlice";
import dayjs from "dayjs";
import {Add, ArrowBack, NextWeekOutlined, WorkOffOutlined} from "@mui/icons-material";
import Button from "../../../../components/Buttons/Button";
import routes from "../../../../routes";
import WorkItemDialog, {WorkItemDialogData} from "./WorkItemDialog";
import {ensureGeoPoints, fetchGeoPoints, selectAllGeoPoints} from "../../../../store/geoPointSlice";
import {WorkItemForm} from "../types";
import {setToast} from "../../../../store/toastSlice";
import {WorkSheetDetails} from "./WorkSheetDetails";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {mapErrors} from "../../../../utils/errorMapping";
import {correspondsWith, strToDecimal} from "../../../../utils/utils";
import {WorkScheduleItem} from "../../../../API/workSchedule/types";
import SplitWorkItemDialog, {SplitWorkItemDialogData, SplitWorkItemFormData} from "./SplitWorkItemDialog";
import {getOdometerReadingRequest, ReadingFormData} from "../../../../components/FormTemplates/ReadingForm/ReadingForm";
import {FormikHelpers} from "formik";
import EditReadingDialog, {EditReadingDialogData} from "./EditReadingDialog";
import {getDayjsDateTimeFromDateWithModifier, getHhMmFromDate} from "../../../../utils/dateUtils";
import AddReadingDialog, {AddReadingDialogData} from "./AddReadingDialog";
import {selectBusOrDriverIdByResourceId} from "../store/selectors";
import {TripWorkItemChanges, TripWorkItemChangesWithRoute} from "../../../../API/tripDefinition/types";
import {AddReadingFormData} from "../../../../components/FormTemplates/ReadingForm/AddReadingForm";


export default function WorkSheetDetailsView() {
    const [workSheet, setWorkSheet] = useState<ApiWorkSheetDetails | undefined>(undefined);
    const busOrDriverId = useAppSelector(state => selectBusOrDriverIdByResourceId(state, workSheet?.resourceId ?? 0, workSheet?.resourceType ?? ResourceType.DRIVER));
    const [secondaryWorkSheets, setSecondaryWorkSheets] = useState<ApiWorkSheetDetails[]>([]);
    const [editWorkItemDialogData, setEditWorkItemDialogData] = useState<WorkItemDialogData | undefined>(undefined);
    const [splitWorkItemDialogData, setSplitWorkItemDialogData] = useState<SplitWorkItemDialogData | undefined>(undefined);
    const [editReadingDialogData, setEditReadingDialogData] = useState<EditReadingDialogData | undefined>(undefined);
    const [addReadingDialogData, setAddReadingDialogData] = useState<AddReadingDialogData | undefined>(undefined);
    const [busWorkSheets, setBusWorkSheets] = useState<WorkScheduleItem[]>([]);
    const [driverWorkSheets, setDriverWorkSheets] = useState<WorkScheduleItem[]>([]);
    const geoPoints = useAppSelector(selectAllGeoPoints);
    const { id} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [unplannedWorkItems, setUnplannedWorkItems] = useState<WorkSheetWorkItem[]>([]);
    const selectedRegion = useAppSelector(selectSelectedRegion);

    useEffect(() => {
        if (id) {
            getWorkSheet(id)
                .then(({workSheet, secondaryWorkSheets}) => {
                    setWorkSheet(workSheet);
                    setSecondaryWorkSheets(secondaryWorkSheets);
                })
                .catch(() => {
                    dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
                });
        }
    }, [id]);

    useEffect(() => {
        if (workSheet) {
            getWorkSheetsByRegionAndDate(selectedRegion?.id ?? 0, dayjs(workSheet.startDate)).then(
                result => {
                    setBusWorkSheets(
                        result.workScheduleItems
                            .filter(item => item.resourceType === ResourceType.VEHICLE && item.resourceId)
                    );
                    setDriverWorkSheets(
                        result.workScheduleItems
                            .filter(item => item.resourceType === ResourceType.DRIVER && item.resourceId)
                    );
                }
            )
        }
    }, [workSheet]);

    useEffect(() => {
        dispatch(fetchBuses());
        dispatch(fetchDrivers());
        dispatch(ensureGeoPoints());
    }, []);

    useEffect(() => {
        if (workSheet && selectedRegion) {
            getUnplannedWorkItems(selectedRegion.id, dayjs(workSheet.startDate), workSheet.resourceType)
                .then(result => setUnplannedWorkItems(result))
                .catch(() => {
                    dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
                });
        }
    }, [workSheet, selectedRegion]);

    const handleCloseForm = () => setEditWorkItemDialogData(undefined);

    const getOrderOfFirstPoint = (formData: WorkItemForm, workItem: WorkSheetWorkItem | undefined) => {
        if (formData.route.length > 0 && workItem?.route && workItem.route.length > 0) {
            return Math.min(...workItem.route.map(p => p.order));
        } else {
            return 1;
        }
    };

    const handleSave = (formData: WorkItemForm, workItem?: WorkSheetWorkItem) => {
        const startTime = getDayjsDateTimeFromDateWithModifier(formData.startTime, formData.startTimeIsOnNextDay, workSheet?.startDate);
        const endTime = getDayjsDateTimeFromDateWithModifier(formData.endTime, formData.endTimeIsOnNextDay, workSheet?.startDate);
        const orderOfFirstPoint = getOrderOfFirstPoint(formData, editWorkItemDialogData?.workItem);
        const route: RoutePointRequest[] = formData.route.length > 0
            ? formData.route.map((point, index) => {
                const existingPoint = geoPoints.find(existingPoint => correspondsWith(point, existingPoint));
                if (existingPoint) {
                    return {...point, ...existingPoint, order: orderOfFirstPoint + index, time: point.time ? getHhMmFromDate(point.time) : undefined};
                } else {
                    return {...point, name: point.geoPoint?.name ?? '', order: orderOfFirstPoint + index, time: point.time ? getHhMmFromDate(point.time) : undefined};
                }
            })
            : [];
        const saveWorkItem: SaveWorkItem = {
            busWorkSheetId: workSheet?.resourceType === ResourceType.VEHICLE ? workSheet.id : formData.oppositeResource?.id ?? undefined,
            date: workSheet?.startDate ?? '',
            distance: strToDecimal(formData.distance ?? ''),
            comment: formData.comment.length > 0 ? formData.comment : null,
            driverWorkSheetId: workSheet?.resourceType === ResourceType.DRIVER ? workSheet.id : formData.oppositeResource?.id ?? undefined,
            endDateTime: endTime.format('YYYY-MM-DDTHH:mm:ss'),
            startDateTime: startTime.format('YYYY-MM-DDTHH:mm:ss'),
            tripDefinitionId: formData.tripDefinition?.id ?? undefined,
            route: route,
            type: formData.type,
        };

        if (workItem) {
            updateWorkItem(workItem.id, saveWorkItem)
                .then(savedWorkItem => {
                    fetchAdditionalSecondaryWorkSheet(savedWorkItem);
                    setWorkSheet(prevState => {
                        if (!prevState) {
                            return prevState;
                        }
                        const existingItemId = prevState.workItems.findIndex(item => item.id === workItem.id);
                        const workItems = [...prevState.workItems];
                        if (existingItemId > -1) {
                            workItems.splice(existingItemId, 1, {
                                ...savedWorkItem,
                            });
                        } else {
                            workItems.push(savedWorkItem);
                        }

                        return {
                            ...prevState,
                            workItems: workItems,
                        }
                    })
                })
                .catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse muutmisel tekkis viga'}));
                })
                .finally(() => {
                    dispatch(fetchGeoPoints());
                });
        } else {
            createWorkItem(saveWorkItem)
                .then(workItem => {
                    setWorkSheet(prevState => {
                        if (!prevState) {
                            return prevState;
                        }

                        return {
                            ...prevState,
                            workItems: [...prevState.workItems, workItem],
                        }
                    })
                })
                .catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse lisamisel tekkis viga'}));
                })
                .finally(() => {
                    dispatch(fetchGeoPoints());
                });
        }
    };

    const removeWorkItemFromWorkSheet = (workItem: WorkSheetWorkItem) => {
        setWorkSheet(prevState => {
            if (!prevState) {
                return prevState;
            }

            return {
                ...prevState,
                workItems: prevState.workItems.filter(item => item.id !== workItem.id),
            };
        });
    };

    const handleRemove = (workItem: WorkSheetWorkItem) => {
        if ((workItem.busWorkSheetId && workItem.driverWorkSheetId) || workItem.type === WorkGroupItemType.TRIP_DEFINITION) {
            if (confirm('Kas soovid tegevuse sellelt sõidulehelt eemaldada?')) {
                const route: RoutePointRequest[] = workItem.route && workItem.route.length > 0
                    ? workItem.route.map((point) => {
                        const existingPoint = geoPoints.find(existingPoint => existingPoint.name === point.name);
                        if (existingPoint) {
                            return {...point, ...existingPoint, order: point.order};
                        } else {
                            return {...point, name: point.name, order: point.order};
                        }
                    })
                    : [];
                const saveWorkItem: SaveWorkItem = {
                    ...workItem,
                    busWorkSheetId: workSheet?.resourceType === ResourceType.VEHICLE || !workItem.busWorkSheetId ? undefined : workItem.busWorkSheetId,
                    driverWorkSheetId: workSheet?.resourceType === ResourceType.DRIVER || !workItem.driverWorkSheetId ? undefined : workItem.driverWorkSheetId,
                    route: route,
                };
                updateWorkItem(workItem.id, saveWorkItem)
                    .then(() => removeWorkItemFromWorkSheet(workItem))
                    .catch(error => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse eemaldamisel tekkis viga'}));
                    });
            }
        } else {
            if (confirm('Kas soovid tegevuse kustutada?')) {
                deleteWorkItem(workItem.id)
                    .then(() => removeWorkItemFromWorkSheet(workItem))
                    .catch(error => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse kustutamisel tekkis viga'}));
                    });
            }
        }
    };

    const oppositeWorkSheets = useMemo(() => {
            return workSheet?.resourceType === ResourceType.DRIVER ? busWorkSheets : driverWorkSheets;
        }, [busWorkSheets, driverWorkSheets, workSheet]
    );

    const isOriginalResource = (workItem: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}) => {
        const workSheetId = id ? parseInt(id) : 0;

        return workSheet?.resourceType === ResourceType.DRIVER
            ? workSheetId === workItem.driverWorkSheetId
            : workSheetId === workItem.busWorkSheetId
    };

    const handleEdit = (workItem: WorkSheetWorkItem) => {
        if (workSheet) {
            setEditWorkItemDialogData({
                workSheet: workSheet,
                workItem: workItem,
                unplannedWorkItems: unplannedWorkItems,
                oppositeWorkSheets: oppositeWorkSheets,
            });
        }
    };

    const handleSplit = (workItem: WorkSheetWorkItem) => {
        if (workItem) {
            setSplitWorkItemDialogData({
                workItem: workItem,
                busWorkSheets: busWorkSheets,
                driverWorkSheets: driverWorkSheets,
            });
        }
    };
    const handleCloseSplitDialog = () => setSplitWorkItemDialogData(undefined);
    const handleSaveSplitWorkItem = (tripId: number, formData: SplitWorkItemFormData, isAvlVersion: boolean) => {
        if (workSheet) {
            if (isAvlVersion) {
                const updatedWorkItems: TripWorkItemChangesWithRoute[] = formData.parts.map(workItem => ({
                    id: workItem.workItemId,
                    routePointId: workItem.splitRoutePoint?.id ?? 0,
                    distance: strToDecimal(workItem.distance ?? ''),
                    busWorkSheetId: workItem.busWorkSheetId?.id,
                    driverWorkSheetId: workItem.driverWorkSheetId?.id,
                }));

                saveTripWorkItemsWithRouteOnDate(tripId, updatedWorkItems, workSheet.startDate)
                    .then((result) => {
                        fetchAdditionalSecondaryWorkSheets(result);
                        upsertWorkSheetWorkItems(result, tripId);
                        dispatch(setToast({type: 'success', text: 'Reis edukalt poolitatud'}));
                        setSplitWorkItemDialogData(undefined);
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi salvestamisel tekkis viga'}));
                    });
            } else {
                const updatedWorkItems: TripWorkItemChanges[] = formData.parts.map(workItem => ({
                    id: workItem.workItemId,
                    tripSegmentId: workItem.tripSegmentId,
                    startDateTime: getDayjsDateTimeFromDateWithModifier(workItem.startTime, workItem.startTimeIsOnNextDay, workSheet?.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    endDateTime: getDayjsDateTimeFromDateWithModifier(workItem.endTime, workItem.endTimeIsOnNextDay, workSheet?.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    distance: strToDecimal(workItem.distance ?? ''),
                    busWorkSheetId: workItem.busWorkSheetId?.id,
                    driverWorkSheetId: workItem.driverWorkSheetId?.id,
                }));

                saveTripWorkItemsOnDate(tripId, updatedWorkItems, workSheet.startDate)
                    .then((result) => {
                        fetchAdditionalSecondaryWorkSheets(result);
                        upsertWorkSheetWorkItems(result, tripId);
                        dispatch(setToast({type: 'success', text: 'Reis edukalt poolitatud'}));
                        setSplitWorkItemDialogData(undefined);
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi salvestamisel tekkis viga'}));
                    });
            }
        }
    };

    const fetchAdditionalSecondaryWorkSheet = (workItem: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}) => {
        const secondaryWorkSheetId = workSheet?.resourceType === ResourceType.DRIVER
            ? workItem.busWorkSheetId
            : workItem.driverWorkSheetId;

        if (secondaryWorkSheetId && !secondaryWorkSheets.find(workSheet => workSheet.id === secondaryWorkSheetId)) {
            getWorkSheet(secondaryWorkSheetId)
                .then(result => {
                    setSecondaryWorkSheets(prevState => {
                        return [...prevState, result.workSheet];
                    });
                })
                .catch(() => {
                    dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
                });
        }
    };

    const fetchAdditionalSecondaryWorkSheets = (workItems: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}[]) => {
        const newSecondaryWorkSheetIds: number[] = []

        workItems.forEach(workItem => {
            const secondaryWorkSheetId = workSheet?.resourceType === ResourceType.DRIVER
                ? workItem.busWorkSheetId
                : workItem.driverWorkSheetId

            if (
                secondaryWorkSheetId && !newSecondaryWorkSheetIds.includes(secondaryWorkSheetId)
                && !secondaryWorkSheets.find(workSheet => workSheet.id === secondaryWorkSheetId)
            ) {
                newSecondaryWorkSheetIds.push(secondaryWorkSheetId);
            }
        });

        if (!newSecondaryWorkSheetIds.length) return;

        Promise.all(newSecondaryWorkSheetIds.map(async (secondaryWorkSheetId) => getWorkSheet(secondaryWorkSheetId)))
            .then(result => {
                setSecondaryWorkSheets(prevState => [...prevState, ...result.map(response => response.workSheet)]);
            })
            .catch(() => {
                dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
            });
    };

    const upsertWorkSheetWorkItems = (workItems: WorkSheetWorkItem[], tripId: number) => {
        setWorkSheet(prevState => {
            if (!prevState) return prevState;

            const updatedWorkSheetWorkItems = [
                ...prevState.workItems.filter(workItem => workItem.tripId !== tripId),
                ...workItems.filter(workItem => isOriginalResource(workItem))
            ];

            return {
                ...prevState,
                workItems: updatedWorkSheetWorkItems,
            }
        });
    };

    const handleNew = () => {
        if (workSheet) {
            setEditWorkItemDialogData({
                workSheet: workSheet,
                workItem: undefined,
                unplannedWorkItems: unplannedWorkItems,
                oppositeWorkSheets: oppositeWorkSheets,
            });
        }
    };

    const handleConfirmChange = (newStatus: WorkScheduleItemStatus) => {
        if (workSheet) {
            if (newStatus === WorkScheduleItemStatus.GENERATED
                && !confirm('Kas soovid tagastatud sõidulehe uuesti genereeritud staatusesse viia?')
            ) return;

            const saveWorkSheet: SaveWorkScheduleItem = {
                ...workSheet,
                driverContractId: workSheet.resourceType === ResourceType.DRIVER ? workSheet.resourceId : undefined,
                busId: workSheet.resourceType === ResourceType.VEHICLE ? workSheet.resourceId : undefined,
                status: newStatus,
                comment: workSheet.comment ?? '',
                type: WorkScheduleItemType.WORK_GROUP
            };
            updateWorkScheduleItem(workSheet.id, saveWorkSheet)
                .then(() => {
                    setWorkSheet(prevState => prevState ? {...prevState, status: newStatus} : prevState);
                }).catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Sõidulehe tagastamisel ilmnes viga'}));
                });
        }
    };

    const handleOpenReadingDialog = (reading: OdometerReadingWithBusId) => {
        if (workSheet) setEditReadingDialogData({workSheet: workSheet, reading: reading});
    };

    const handleCloseReadingDialog = () => setEditReadingDialogData(undefined);

    const handleSaveReading = (originalReading: OdometerReadingWithBusId, formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        const data: OdometerReadingRequest = getOdometerReadingRequest(formData)

        apiUpdateOdometerReading(originalReading.id, data)
            .then(() => {
                dispatch(setToast({type: 'success', text: data.fuelType ? 'Tankimine uuendatud' : 'Odomeetri näit uuendatud'}));
                setWorkSheet(prevState => {
                    if (!prevState) return prevState;
                    const updatedReadings = [
                        ...prevState.odometerReadings.filter(reading => reading.id !== originalReading.id),
                        {...originalReading, ...data}
                    ];
                    return {...prevState, odometerReadings: updatedReadings};
                });
                helpers.setSubmitting(false);
                handleCloseReadingDialog();
            })
            .catch((error) => {
                helpers.setSubmitting(false);
                dispatch(setToast({
                    type: 'error',
                    text: mapErrors(error) ?? `${formData.fuelType ? 'Tankimise' : 'Odomeetri näidu'} uuendamisel ilmnes viga`
                }));
            });
    };

    const handleCloseAddReadingDialog = () => setAddReadingDialogData(undefined);

    const handleSaveNewReading = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => {
        if (workSheet && workSheet.resourceType === ResourceType.DRIVER && busOrDriverId) {
            const request: OdometerReadingRequest = {driverId: busOrDriverId, ...getOdometerReadingRequest(formData)};

            saveOdometerReading(formData.busId, request)
                .then(response => {
                    dispatch(setToast({type: 'success', text: `${response.fuelType ? 'Tankimine' : 'Odomeetri näit'} lisatud`}));
                    setWorkSheet(prevState => {
                        if (!prevState) return prevState;
                        const updatedReadings = [
                            ...prevState.odometerReadings,
                            {...response, busId: formData.busId},
                        ];
                        return {...prevState, odometerReadings: updatedReadings};
                    });
                    helpers.setSubmitting(false);
                    handleCloseAddReadingDialog()
                })
                .catch((error) => {
                    helpers.setSubmitting(false);
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? `${formData.fuelType ? 'Tankimise' : 'Odomeetri näidu'} lisamisel ilmnes viga`
                    }));
                });
        }
    };

    const handleDeleteReading = (reading: OdometerReadingWithBusId) => {
        if (confirm(`Kas soovid ${reading.fuelType ? 'tankimise' : 'odomteetri näidu'} kustutada?`)) {
            deleteOdometerReading(reading.id)
                .then((data) => {
                    dispatch(setToast({type: 'success', text: data.fuelType ? 'Tankimine kustutatud' : 'Odomeetri näit kustutatud'}));
                    setWorkSheet(prevState => {
                        if (!prevState) return prevState;
                        return {
                            ...prevState,
                            odometerReadings: prevState.odometerReadings
                                .filter(omr => omr.id !== reading.id)
                        };
                    })
                })
                .catch((error) => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? `${reading.fuelType ? 'Tankimise' : 'Odomeetri näidu'} kustutamisel ilmnes viga`
                    }));
                });
        }
    };

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex'}}>
                    <Typography component="h1" variant="h5" pt={{xs: 1, sm: 0}}>
                        Sõiduleht
                    </Typography>
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Box>
                        <Button
                            text="Tagasi"
                            onClick={() => navigate(routes.AUTHENTICATED.WORK_SHEETS.ROOT)}
                            startIcon={<ArrowBack />}
                            variant="outlined"
                        />
                    </Box>
                    {workSheet?.status === WorkScheduleItemStatus.CONFIRMED ?
                        <Box sx={{ml: 1}}>
                            <Button
                                text="Tühista tagastamine"
                                onClick={() => handleConfirmChange(WorkScheduleItemStatus.GENERATED)}
                                startIcon={<WorkOffOutlined />}
                            />
                        </Box>
                        :
                        <>
                            <Box sx={{ml: 1}}>
                                <Button
                                    text="Tagasta"
                                    onClick={() => handleConfirmChange(WorkScheduleItemStatus.CONFIRMED)}
                                    startIcon={<NextWeekOutlined />}
                                />
                            </Box>
                            <Box sx={{ml: 1}}>
                                <Button text="Lisa tegevus" onClick={handleNew} startIcon={<Add />} />
                            </Box>
                        </>
                    }
                </Box>
            </Box>
            <LoaderWithChildren loading={!workSheet}>
                {workSheet &&
                    <WorkSheetDetails
                        workSheet={workSheet}
                        secondaryWorkSheets={secondaryWorkSheets}
                        handleDelete={handleRemove}
                        handleEdit={handleEdit}
                        handleSplit={handleSplit}
						handleEditOdometerReading={handleOpenReadingDialog}
                        handleAddOdometerReading={setAddReadingDialogData}
                        handleDeleteOdometerReading={handleDeleteReading}
                    />
                }
            </LoaderWithChildren>
            {editWorkItemDialogData &&
                <WorkItemDialog
                    dialogData={editWorkItemDialogData}
                    handleCloseDialog={handleCloseForm}
                    handleSave={handleSave}
                />
            }
            {splitWorkItemDialogData &&
                <SplitWorkItemDialog
                    dialogData={splitWorkItemDialogData}
                    handleCloseDialog={handleCloseSplitDialog}
                    handleSave={handleSaveSplitWorkItem}
                />
            }
            {editReadingDialogData &&
                <EditReadingDialog
                    dialogData={editReadingDialogData}
                    handleCloseDialog={handleCloseReadingDialog}
                    handleSave={handleSaveReading}
                />
            }
            {addReadingDialogData &&
                <AddReadingDialog
                    dialogData={addReadingDialogData}
                    handleCloseDialog={handleCloseAddReadingDialog}
                    handleSave={handleSaveNewReading}
                />
            }
        </Box>
    );
}
