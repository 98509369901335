import {useResourceOptions} from "../../../../hooks";
import {Box, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {busCardStyles, DefectsTooltipContent} from "./BusesWithDefects";
import StaticIcon from "../../../../components/Icons/StaticIcon";
import {ConstructionRounded} from "@mui/icons-material";


const DEFAULT_BUSES_COLLAPSE_LENGTH = 10;
const DEFAULT_BUSES_VISIBLE_LENGTH = DEFAULT_BUSES_COLLAPSE_LENGTH - 2;

const AvailableBuses = () => {
    const {availableBuses} = useResourceOptions();
    const [expandAvailableBuses, setExpandAvailableBuses] = useState(availableBuses.length > DEFAULT_BUSES_COLLAPSE_LENGTH);
    const availableBusesLength = availableBuses.length;

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', my: 0.5}}>
            {availableBusesLength > 0 && <Typography variant="body2" pr={1}>Vabad bussid:</Typography>}
            {availableBuses.slice(0, expandAvailableBuses ? availableBusesLength : DEFAULT_BUSES_VISIBLE_LENGTH).map(bus =>
                <Tooltip key={bus.id} title={bus.defects.length > 0 ? <DefectsTooltipContent defects={bus.defects} /> : undefined} arrow placement="top">
                    <Box sx={busCardStyles}>
                        <Typography variant="body2" sx={{display: 'flex', alignItems: 'center', mr: 1}}>
                            {bus.name}
                            {bus.defects.length > 0 && <ConstructionRounded sx={{color: 'text.secondary', fontSize: '16px', ml: '2px'}} />}
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            {availableBusesLength > DEFAULT_BUSES_COLLAPSE_LENGTH &&
                <Typography
                    variant="body2"
                    sx={{...busCardStyles, cursor: 'pointer', pr: 1}}
                    onClick={() => setExpandAvailableBuses(prevState => !prevState)}
                >
                    <span style={{maxHeight: '20px', paddingRight: '5px'}}>
                        {expandAvailableBuses ? <StaticIcon type="HIDE" noPadding /> : <StaticIcon type="VIEW" noPadding />}
                    </span>
                    {expandAvailableBuses ? 'vähem busse' : `veel ${availableBusesLength - DEFAULT_BUSES_VISIBLE_LENGTH} vaba bussi`}
                </Typography>
            }
        </Box>
    );
};

export default AvailableBuses;
