import {WorkGroupItemType, ResourceType} from "../workGroup/types";
import {WorkScheduleItemStatus} from "../workSchedule/types";
import {OdometerReadingWithBusId, Region, RepetitionRules, RoutePoint, RoutePointRequest} from "../types";

export interface WorkSheet {
    id?: number;
    resourceId: number | null;
    resourceType: ResourceType;
    workGroupId?: number;
    workGroupCode?: string;
    regionId: number;
    date: string;
    connectedWorkSheets: WorkSheet[];
    startTime?: string;
    endTime?: string;
    distance?: number;
    status: WorkScheduleItemStatus;
}

export interface WorkSheetDetails {
    id: number;
    resourceId: number;
    resourceType: ResourceType;
    startDate: string;
    endDate: string;
    status: WorkScheduleItemStatus;
    workGroupId: number | null;
    workItems: WorkSheetWorkItem[];
    driverComment?: string;
    comment?: string;
    odometerReadings: OdometerReadingWithBusId[];
}

export interface WorkSheetWorkItem {
    id: number;
    type: WorkGroupItemType;
    route: RoutePoint[] | null;
    date: string;
    startDateTime: string;
    endDateTime: string;
    comment: string | null;
    distance: number | null;
    busWorkSheetId: number | null;
    driverWorkSheetId: number | null;
    tripId?: number;
    tripSegmentId?: number;
    tripDefinitionCode?: string;
    tripDefinitionLineNumber?: string;
    tripDefinitionRegionId?: number;
    tripDefinitionComment?: string;
    tripDefinitionRepetition?: RepetitionRules | null;
}

export interface SaveWorkSheet {
    id?: number;
    resourceId: number | null;
    resourceType: ResourceType;
    workGroupId: number | null;
    date: string;
    regionId: number;
    status: WorkScheduleItemStatus;
}

export interface GenerateWorkSheetRequest {
    id: number;
    date: string;
}

export interface SaveWorkItem {
    type: WorkGroupItemType;
    date: string;
    startDateTime: string;
    endDateTime: string;
    distance: number | null;
    comment: string | null;
    route: RoutePointRequest[] | null;
    busWorkSheetId?: number;
    driverWorkSheetId?: number;
    tripDefinitionId?: number;
}

export interface TripSegmentResources {
    busWorkSheetId?: number;
    driverWorkSheetId?: number;
}

export interface DriverWorkSheetRecord {
    id: number;
    driverId: number;
    driverFirstName: string;
    driverLastName: string;
    startDateTime: string;
    endDateTime: string;
    workGroupCode?: string;
    region?: Region;
}

export const workGroupItemTypesWithDistance = [WorkGroupItemType.TRIP_DEFINITION, WorkGroupItemType.DEADHEADING];
export const workGroupItemTypesWithComment = [WorkGroupItemType.MAINTENANCE];