import {Box, useTheme} from '@mui/material';
import React, {useEffect} from 'react';
import {WorkGroupItemType} from "../../../../API/types";
import {useAppDispatch, useAppSelector, useCustomRef} from "../../../../hooks";
import {PLANNER_ROW_HEIGHT, PLANNER_SIDEBAR_WIDTH} from "../../constants";
import {ContentScroll, ScrollOrigin} from "../../types";
import {useDrop} from "react-dnd";
import UnassignedTripRow from "./Row";
import UnassignedItemsContainerToolbox from "./Toolbox";
import {ChevronLeft} from "@mui/icons-material";
import {
    selectContentWidth,
    selectIsPlannerLoading,
    selectPlannerTimelineMinWidth,
    selectUnassignedTripRowsMemoized,
    selectUnassignedTripsSectionHeight,
    setContentWidth,
    setUnassignedTripsSectionMaxHeight,
} from "../../../../store/plannerSlice";
import {useWindowSize} from "usehooks-ts";
import {shallowEqual} from "react-redux";


interface UnassignedTripContainerProps {
    contentScrollX: ContentScroll | undefined;
    handleContentScroll: (ref: React.RefObject<HTMLDivElement>, origin: ScrollOrigin) => void;
}

const UnassignedTripContainer = ({contentScrollX, handleContentScroll}: UnassignedTripContainerProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { width } = useWindowSize();

    const isLoading = useAppSelector(selectIsPlannerLoading);
    const unassignedTripRows = useAppSelector(selectUnassignedTripRowsMemoized, shallowEqual);
    const unassignedTripsSectionHeight = useAppSelector(selectUnassignedTripsSectionHeight);
    const contentWidth = useAppSelector(selectContentWidth);
    const plannerTimelineMinWidth = useAppSelector(selectPlannerTimelineMinWidth);

    const [unassignedTripContainerRef] = useCustomRef();
    const [contentWidthRef] = useCustomRef();

    useEffect(() => {
        const newContentWidth = contentWidthRef.current?.clientWidth;
        if (newContentWidth && newContentWidth !== contentWidth) {
            dispatch(setContentWidth(newContentWidth));
        }
    }, [width, plannerTimelineMinWidth]);

    useEffect(() => {
        updateScroll();
    }, [contentScrollX]);

    const updateScroll = () => {
        if (unassignedTripContainerRef.current && contentScrollX && contentScrollX.origin !== 'unassigned') {
            unassignedTripContainerRef.current.scrollLeft = contentScrollX.value;
        }
    };

    const handleUnassignedContainerScroll = () => {
        if (unassignedTripContainerRef.current?.scrollLeft !== contentScrollX?.value) {
            handleContentScroll(unassignedTripContainerRef, 'unassigned');
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, drop] = useDrop({
        accept: WorkGroupItemType.TRIP_DEFINITION,
        drop: () => ({id: 0}),
        canDrop: () => true,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const ExpandUnassignedTripsToggle = () => {
        return (
            <Box sx={{
                position: 'absolute',
                zIndex: 50,
                borderRadius: '20px',
                mt: -1.5,
                ml: `${PLANNER_SIDEBAR_WIDTH - 26}px`,
                backgroundColor: 'common.white',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '20px',
                width: '20px',
                cursor: 'pointer',
                ':hover': {backgroundColor: '#F4F4F4'}
            }}>
                <ChevronLeft
                    onClick={() => {
                        dispatch(setUnassignedTripsSectionMaxHeight(unassignedTripsSectionHeight === '20%' ? '40%' : '20%'));
                    }}
                    sx={{
                        fontSize: '20px',
                        transform: `rotate(${unassignedTripsSectionHeight === '20%' ? '90deg' : '-90deg'})`,
                        color: 'text.secondary'
                    }}
                />
            </Box>
        );
    };

    return (
        <Box sx={{
            position: 'static',
            display: 'flex',
            flexDirection: 'row',
            borderTop: `2px solid ${theme.palette.divider}`,
            overflow: 'hidden',
            flexGrow: 1,
        }}>
            <ExpandUnassignedTripsToggle />
            <UnassignedItemsContainerToolbox />
            <Box
                ref={unassignedTripContainerRef}
                onScroll={() => handleUnassignedContainerScroll()}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: `calc(100% - ${PLANNER_SIDEBAR_WIDTH}px)`,
                    overflowX: 'scroll',
                    borderLeft: `2px solid ${theme.palette.divider}`,
                    overflowY: 'scroll',
                    scrollbarGutter: 'stable',
                    boxSizing: 'border-box',
                    flexGrow: 1,
                }}
            >
                <Box ref={drop} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minWidth: plannerTimelineMinWidth,
                    minHeight: `${PLANNER_ROW_HEIGHT}px`,
                    height: '100%',
                    pb: 0.5,
                }}>
                    <Box ref={contentWidthRef} width="100%">
                        {!isLoading && unassignedTripRows.map((row, index) =>
                            <UnassignedTripRow key={index} unassignedTripIds={row} />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default UnassignedTripContainer;
